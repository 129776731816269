<div class="error-container" *ngIf="model">
  <div class="d-flex flex-column flex-lg-row justify-content-between welcome pb-4 pb-lg-0">
    <img class="rounded d-block" src="assets/images/logosite.png" title="logo" />
    <div class="divider"></div>
    <div class="d-flex align-items-center justify-content-center lead-container">
      <h1 class="lead">{{ model.status }}</h1>
    </div>
  </div>
  <div class="col-md-12 text-center pt-5">
    <p class="fs-4">{{ model.message }}</p>
    <div *ngIf="model.extra.length > 0">
      <p class="fs-4" *ngFor="let item of model.extra">{{ item }}</p>
    </div>
    <button class="btn btn-secondary mt-1" (click)="back()">{{ 'app.ui.nav.go-back' | translate}}</button>
  </div>
</div>
