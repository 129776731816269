import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  sideBarCollapsed: boolean = false;
  environment = environment.environment;
  appVersion = environment.appVersion;
  currentYear!: number;

  onToggleSideNav($event: any) {
    this.sideBarCollapsed = $event;
  }

  
  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }
}
