import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Language } from '@app/core/models/language.model';
import { GlobalizationService } from '@app/core/services/globalization.service';
import { SiteService } from '@app/core/services/site.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

  @Input() cookie: boolean = false;
  @Output() languageChanged = new EventEmitter<Language>();

  languages$: Observable<Language[]> = of([]);
  selectedLanguage: Language | undefined = undefined;

  constructor(private readonly globalizationService: GlobalizationService,
    private readonly translateService: TranslateService,
    private readonly siteService: SiteService) {

  }

  ngOnInit(): void {
    this.languages$ = this.globalizationService.getAllLanguages();
    this.languages$.subscribe(l => this.selectedLanguage = l.filter(x => x.languageCode === this.translateService.currentLang)[0]);

  }

  onChange() {
    this.languageChanged.emit(this.selectedLanguage);

    if (this.cookie && this.siteService.setCookieLanguage(this.selectedLanguage?.languageCode)) {
        this.translateService.use(this.selectedLanguage?.languageCode!).subscribe(_ => {
            location.reload();
        });
    }
  }
}
