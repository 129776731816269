import { Injectable } from "@angular/core";
import { LANGUAGE_COOKIE } from "@app/app.module";
import { CookieService } from "ngx-cookie-service";
import { District } from "../models/district.model";

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  siteFilterCookie: string = 'sv_global_site_district_filter';

  constructor(private readonly cookieService: CookieService) { }

  hasGlobalSiteDistrictFilter(): boolean {
    return this.cookieService.check(this.siteFilterCookie);
  }

  setGlobalSiteDistrictFilter(district: District | null, shouldReload: boolean | false): void {
    console.log(`setGlobalSiteDistrictFilter: ${district?.id}`);

    this.cookieService.set(this.siteFilterCookie, district !== null ? district.id : "", 365);

    if (shouldReload) {
      window.location.reload();
    }
  }

  getGlobalSiteDistrictFilter(): string | undefined {
    if (this.hasGlobalSiteDistrictFilter()) {
      let value = this.cookieService.get(this.siteFilterCookie);

      if (value === "") {
        return undefined;
      }

      return this.cookieService.get(this.siteFilterCookie);
    }

    return undefined;
  }

  hasGlobalSiteDistrictFilterAll(): boolean {
    return this.hasGlobalSiteDistrictFilter()
      && this.getGlobalSiteDistrictFilter() === undefined;
  }

  hasGlobalSiteDistrictFilterAntwerp(): boolean {
    return (this.hasGlobalSiteDistrictFilter()
      && this.getGlobalSiteDistrictFilter() === '09edff5e-5882-4c0f-ad17-054132295239')!;
  }

  hasGlobalSiteDistrictFilterFlemishBrabant(): boolean {
    return (this.hasGlobalSiteDistrictFilter()
      && this.getGlobalSiteDistrictFilter() === 'd15af377-4abe-4aa2-a8be-1e9c99ffe63b')!;
  }

  setSiteFilter(districtId: string): boolean {
    let shouldReload = false;

    if (districtId != undefined) {
      if (this.cookieService.check(this.siteFilterCookie)) {
        let currentDistrict = this.cookieService.get(this.siteFilterCookie);

        console.debug(`cookie has value currentDistrict: ${currentDistrict}`)

        if (currentDistrict !== districtId) {
          this.cookieService.set(this.siteFilterCookie, districtId.toString(), { path: '/' });

          console.debug(`cookie has new value currentDistrict: ${districtId}`);
          shouldReload = true;
        }
      }
      else {
        console.debug(`cookie has no value setting: ${districtId}`)
        this.cookieService.set(this.siteFilterCookie, districtId.toString(), { path: '/' });
        shouldReload = true;
      }
    }

    return shouldReload;
  }

  setCookieLanguage(languageCode: string | undefined): boolean {
    let shouldReload = false;

    if (languageCode != undefined) {
      if (this.cookieService.check(LANGUAGE_COOKIE)) {
        let currentLanguageCode = this.cookieService.get(LANGUAGE_COOKIE);

        console.debug(`cookie has value currentLanguageCode: ${currentLanguageCode}`)

        if (currentLanguageCode !== languageCode) {
          this.cookieService.set(LANGUAGE_COOKIE, languageCode!, { path: '/' });

          console.debug(`cookie has new value languageCode: ${languageCode}`);
          shouldReload = true;
        }
      } else {
        console.debug(`cookie has no value setting: ${languageCode}`)
        this.cookieService.set(LANGUAGE_COOKIE, languageCode!, { path: '/' });
        shouldReload = true;
      }
    }
    else {
      shouldReload = true;
      this.cookieService.set(LANGUAGE_COOKIE, 'nl-BE', { path: '/' });
    }

    return shouldReload;
  }
}
