import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Language } from "../models/language.model";
import { Translation } from "../models/translation.model";
import { CountryModel } from "../models/country.list.model";

@Injectable({
  providedIn: 'root'
})
export class GlobalizationService {

  private httpOptions: Object = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    observe: 'body',
    reponseType: 'json'
  };

  constructor(private http: HttpClient) { }

  getAllLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>('globalization/lang');
  }

  getTranslationsByLanguage(lang: string): Observable<Translation[]> {
    return this.http.get<Translation[]>(`globalization/${lang}`);
  }
  
  getAllCountries(): Observable<CountryModel[]>{
    return this.http.get<CountryModel[]>('countries');
  }

  getTranslation(lang: string, key: string): Observable<Translation> {
    return this.http.get<Translation>(`globalization/${lang}/${key}`);
  }

  updateTranslation(translation: Translation): Observable<Translation> {
    return this.http.put<Translation>(`globalization/${translation.languageCode}/${translation.translationKey}`, translation, this.httpOptions);
  }

  deleteTranslation(translationKey: string){
    return this.http.delete(`globalization/${translationKey}`, this.httpOptions);
  }
}
