import { Injectable } from "@angular/core";
import { select } from "@ngneat/elf";
import { masterdataStore } from "./masterdata.store";
import { MasterDataProps } from "./masterdata.props";

@Injectable({ providedIn: 'root' })
export class MasterdataRepository {
  $cities = masterdataStore.pipe(select((state) => state.cities));
  $districts = masterdataStore.pipe(select((state) => state.districts));

  updateCities(cities: MasterDataProps['cities']) {
    masterdataStore.update((state) => ({
      ...state,
      cities
    }));
  }

  getCities() {
    return masterdataStore.getValue().cities;
  }

  updateDistricts(districts: MasterDataProps['districts']) {
    masterdataStore.update((state) => ({
      ...state,
      districts
    }));
  }

  getDistricts() {
    return masterdataStore.getValue().districts;
  }
}
