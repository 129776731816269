import { Component, Input } from '@angular/core';
import { faAtom, faBuildingShield, faCalendar, faCalendarAlt, faChess, faEnvelope, faGears, faGlobe, faHandshake, faHouse, faLanguage, faLeaf, faMailBulk, faNewspaper, faPaperclip, faPeopleGroup, faPlay, faSnowflake, faStamp, faTachometerAlt, faTag, faTrophy, faUserGear, faUserLock, faUsers } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-admin-nav',
  templateUrl: './admin-nav.component.html',
  styleUrls: ['./admin-nav.component.scss']
})
export class AdminNavComponent {
  faAtom = faAtom;
  faBuildingShield = faBuildingShield;
  faCalendar = faCalendar;
  faCalendarAlt = faCalendarAlt;
  faChess = faChess;
  faEnvelope = faEnvelope;
  faGears = faGears;
  faGlobe = faGlobe;
  faHandshake = faHandshake;
  faHouse = faHouse;
  faLanguage = faLanguage;
  faLeaf = faLeaf;
  faMailBulk = faMailBulk;
  faNewspaper = faNewspaper;
  faPeopleGroup = faPeopleGroup;
  faPlay = faPlay;
  faSnowflake = faSnowflake;
  faStamp = faStamp;
  faTachometerAlt = faTachometerAlt;
  faTag = faTag;
  faTrophy = faTrophy;
  faUsers = faUsers;
  faUserLock = faUserLock;
  faUserGear = faUserGear;
  faPaperclip = faPaperclip;

  @Input() sideBarState: boolean = false;
}
