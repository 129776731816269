<div class="admin-area-wrapper" [ngClass]="{ 'toggled': sideBarCollapsed }">
  <app-admin-nav [sideBarState]="sideBarCollapsed"></app-admin-nav>
  <div class="admin-area-content-wrapper d-flex flex-column">
    <app-admin-top-nav [sideBarState]="sideBarCollapsed"
      (sideBarCollapse)="onToggleSideNav($event)"></app-admin-top-nav>
    <div class="content-wrapper">
      <div class="card">
        <router-outlet></router-outlet>
      </div>
    </div>

    <footer class="sticky-footer">
      <div class="container-fluid my-auto">
        <div class="d-flex flex-row justify-content-between pt-2">
          <p>{{ 'app.ui.footer.copyright' | translate }} {{ currentYear }}</p>
          <p>{{ 'app.ui.footer.environment-version' | translate: { environment: environment, version: appVersion } }}
          </p>
          <p>{{ 'app.ui.footer.developed-by' | translate }} <a rel="noopener" href="https://resonate.be"
              target="_blank">
              <img src="assets/images/resonate_logo.png" height="15" alt="resonate_logo"></a>
          </p>
        </div>
      </div>
    </footer>
  </div>

  <p-toast></p-toast>

</div>
