import { Component, HostListener } from '@angular/core';
import { faArrowUp, faJetFighterUp } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent {
  showToTop: boolean = false;
  faJetFighterUp = faJetFighterUp;
  faArrowUp = faArrowUp;

  @HostListener("window:scroll", []) onWindowScroll() {
    if (window.scrollY > 150) {
      this.showToTop = true;
    } else {
      this.showToTop = false;
    }
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
