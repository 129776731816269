import { inject } from "@angular/core";
import { ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { firstValueFrom } from "rxjs";

export const AuthResolver: ResolveFn<any> = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);

  return await firstValueFrom(authService.isAuthenticated$);
}
