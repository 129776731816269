import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { ErrorPageModel } from '../error-page/error-page.component';
import { AuthRepository } from '@app/store/auth/auth.repository';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {
  public model: ErrorPageModel = { status: '401', message: 'You are not authorized to view this page.', extra: []}

  constructor(private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private authRepository: AuthRepository,
    private router: Router) {
      this.activatedRoute.queryParams.subscribe(() => {
        const currentNavigation = this.router.getCurrentNavigation();
        if (currentNavigation?.extras.state) {
          let error = currentNavigation.extras.state['error'];

          this.model.extra.push(error)

          let forceLogout = currentNavigation.extras.state['forceLogout'];
          if (forceLogout) {
            setTimeout(() => {
              combineLatest([this.auth.logout({ logoutParams: { returnTo: document.location.origin } })])
                .subscribe({
                  next: () => this.authRepository.clearStore()
                });
            }, 2000);
          }
        }
      });
  }
}
