import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpMethod } from '@auth0/auth0-angular';
import { Observable, tap } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(private readonly localStorageService: LocalStorageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method !== HttpMethod.Get) {
      return next.handle(request);
    }

    let cachedResponse = this.getCachedResponse(request.urlWithParams);
    let nextRequest = request.clone();

    if (cachedResponse?.headers.get('etag') !== null) {
      nextRequest.headers.set('etag', cachedResponse?.headers.get('etag')!);
    }

    return next.handle(nextRequest).pipe(tap(response => {
      if (response instanceof HttpResponse && response.status == HttpStatusCode.Ok && response.headers.get('etag') !== null) {
        if (cachedResponse === undefined) {
          console.debug(`Cache entry missing for ${request.urlWithParams}. Adding.`);
          this.setCachedResponse(request.urlWithParams, response);
          cachedResponse = this.getCachedResponse(request.urlWithParams);
        }
        else if (cachedResponse.headers.get('etag') !== response.headers.get('etag')) {
          console.debug(`Cache entry invalid for ${request.urlWithParams}. Renewing.`);
          this.setCachedResponse(request.urlWithParams, response);
        }
        else {
          console.debug(`Serving ${request.urlWithParams} from cache.`);
          response = cachedResponse;
        }
      }
    }));
  }

  private getCachedResponse(key: string): HttpResponse<any> | undefined {
    let cachedItem = this.localStorageService.getItem(key);

    if (cachedItem == undefined)
      return undefined;

    let cachedResponseItem: HttpResponse<any> | undefined = this.deserializeResponse(cachedItem);

    return cachedResponseItem;
  }

  private setCachedResponse(requestUrl: string, response: HttpResponse<any>): void {
    this.localStorageService.setItem(requestUrl, this.serializeResponse(response));
  }

  private serializeResponse(res: HttpResponse<any>): string {
    const response = res.clone();
    const json = JSON.stringify({
      headers: Object.fromEntries(
        response.headers.keys().map(
          (key: string) => [key, response.headers.get(key)]
        )
      ),
      status: response.status,
      statusText: response.statusText,
      url: response.url,
      body: response.body
    })

    return json;
  }

  private deserializeResponse<T = any>(res: string): HttpResponse<T> | undefined {
    const response = JSON.parse(res);
    const headers = new HttpHeaders(response.headers);
    const result = new HttpResponse<any>({
      headers,
      body: response.body,
      status: response.status,
      statusText: response.statusText,
      url: response.url,
    });

    return result;
  }

}
