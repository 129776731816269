import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';

const LANGUAGE_COOKIE = 'SNVL-UI-LANG'

@Injectable()
export class ApiBaseInterceptor implements HttpInterceptor {

  constructor(private readonly injector: Injector) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const translate = this.injector.get(TranslateService);
    const cookieService = this.injector.get(CookieService);

    let lang = translate.getBrowserCultureLang()!;

    if (cookieService.check(LANGUAGE_COOKIE)) {
      lang = cookieService.get(LANGUAGE_COOKIE)
    }

    const apiReq = request.clone({
      url: `${(request.url.indexOf('graphql') > 0) ? environment.settings.graphQLBase : environment.settings.serviceBase}${request.url}`,
      setHeaders: {
        'Accept-Language': translate.getBrowserCultureLang()!
      }
    });

    return next.handle(apiReq);
  }
}
