import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { ApplicationAction } from '@app/features/admin/applicationactions/models/applicationaction.model';
import { AuthRepository } from "@app/store/auth/auth.repository";

@Directive({
  selector: '[action]'
})
export class AuthDirective implements AfterViewInit {
  @Input('action')
  action!: AuthModel;

  constructor(private el: ElementRef, private authRepository: AuthRepository) { }

  ngAfterViewInit(): void {
    this.applyAuthentication();
  }

  applyAuthentication() {
    let actions: ApplicationAction[] = this.authRepository.getApplicationActions();
    let isAuthorized = actions.some(x => x.name == this.action.name && x.category == this.action.category && x.type == this.action.type);

    if (!isAuthorized) {
      this.el.nativeElement.remove();
    }
  }
}

export class AuthModel {
  name! : string;
  type!: string;
  category!: string;
}