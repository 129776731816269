import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CreateUserAccount } from "@app/features/admin/users/models/create-user-account.model";
import { UserSettings } from "@app/features/authenticated/settings/models/user-settings.model";
import { Observable } from "rxjs";
import { CreateUserModel, UpdateUserDetailModel, UpdateUserModel, UpdateUserSecurityModel, UserDetailModel, UserListModel, UserModel, UserPrivacySettings, UserSecurityModel } from "../models/user.model";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private httpOptions: Object = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    observe: 'response',
    reponseType: 'json'
  };

  constructor(private http: HttpClient) {

  }

  getAllUsers(): Observable<UserListModel[]> {
    return this.http.get<UserListModel[]>(`user`);
  }

  getUserByAuthId(authId: string): Observable<UserModel> {
    return this.http.get<UserModel>(`user/${authId}`);
  }

  getUserAccountByEmail(email: string): Observable<any> {
    return this.http.get<any>(`user/account/${email}`);
  }

  getUserByEmail(email: string): Observable<any> {
    return this.http.get<any>(`user/email/${email}`);
  }

  getUserAccountByUserId(id: string): Observable<any> {
    return this.http.get<any>(`user/${id}/account`);
  }

  getUserSecurityByAuthId(authId: string): Observable<UserSecurityModel> {
    return this.http.get<UserSecurityModel>(`user/security/${authId}`);
  }

  getUserDetailById(id: string): Observable<UserDetailModel> {
    return this.http.get<UserDetailModel>(`user/detail/${id}`);
  }

  updateUserDetail(updateModel: UpdateUserDetailModel) {
    return this.http.put<UserDetailModel>(`user/detail/${updateModel.id}`, updateModel, this.httpOptions);
  }

  updateUserSecurity(updateUserModel: UpdateUserSecurityModel) {
    return this.http.put<UserModel>(`user/security/${updateUserModel.id}`, updateUserModel, this.httpOptions);
  }

  createUser(userModel: CreateUserModel) {
    return this.http.post<UserModel>('user', userModel, this.httpOptions);
  }

  createUserAccount(account: CreateUserAccount): Observable<any> {
    return this.http.post<CreateUserAccount>('user/account', account, this.httpOptions);
  }

  updateUser(updateUserModel: UpdateUserModel) {
    return this.http.put<UserModel>(`user/${updateUserModel.id}`, updateUserModel, this.httpOptions);
  }

  anonymizeUser(id: string) {
    return this.http.put<boolean>(`user/security/anonymize/${id}`, this.httpOptions);
  }

  getUserPictureById(id: string): Observable<ImageResponse> {
    return this.http.get<ImageResponse>(`user/${id}/image`);
  }

  addPictureToUser(id: string, file: File) {
    const formData = new FormData();
    formData.append('avatarFile', file);

    return this.http.post<ImageResponse>(`user/${id}/image`, formData);
  }

  removePicture(id: string) {
    return this.http.delete(`user/${id}/image`);
  }

  getUserSettings(userId: string): Observable<UserSettings> {
    return this.http.get<UserSettings>(`user/${userId}/settings`);
  }

  updateUserSettings(settings: UserSettings): Observable<UserSettings> {
    return this.http.put<UserSettings>(`user/${settings.userId}/settings`, settings, this.httpOptions);
  }

  getPlayerExtendMembership(userId: string): Observable<boolean> {
    return this.http.get<boolean>(`user/${userId}/playerExtendMembership`)
  }

  updatePlayerExtendMembership(userId: string, extendMembership: boolean): Observable<boolean> {
    return this.http.put<boolean>(`user/${userId}/playerExtendMembership`, { extendMembership: extendMembership }, this.httpOptions);
  }

  getUserPrivacySettings(userId: string): Observable<UserPrivacySettings> {
    return this.http.get<UserPrivacySettings>(`user/${userId}/privacy`)
  }

  updateUserPrivacySettings(userId: string, privacySettings: UserPrivacySettings): Observable<UserPrivacySettings> {
    return this.http.put<UserPrivacySettings>(`user/${userId}/privacy`, privacySettings, this.httpOptions);
  }
}

export interface ImageResponse {
  image: string;
}
