import { Injectable } from '@angular/core';
import { ApplicationAction } from '@app/features/admin/applicationactions/models/applicationaction.model';
import { Permission } from '@app/features/admin/permissions/models/permission.model';
import { select } from '@ngneat/elf';
import { AuthProps } from './auth.props';
import { authStore } from './auth.store';
import { AuthModel } from '@app/core/directives/auth.directive';
import { UserModel } from '@app/core/models/user.model';

@Injectable({ providedIn: 'root' })
export class AuthRepository {
  $permissions = authStore.pipe(select((state) => state.permissions));
  $applicationActions = authStore.pipe(select((state) => state.applicationActions));

  updatePermissions(permissions: Permission[]) {
    authStore.update((state) => ({
      ...state,
      permissions
    }));
  }

  getPermissions(): Permission[] {
    return authStore.getValue().permissions;
  }

  updateApplicationActions(applicationActions: ApplicationAction[]) {
    authStore.update((state) => ({
      ...state,
      applicationActions
    }));
  }

  getApplicationActions(): ApplicationAction[] {
    return authStore.getValue().applicationActions;
  }

  isAuthorized(applicationaction: AuthModel): boolean {
    return this.getApplicationActions()
      .some((action: ApplicationAction) => action.name == applicationaction.name &&
        action.category == applicationaction.category &&
        action.type == applicationaction.type);
  }

  clearStore() {
    authStore.reset();
  }
}
