import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { permissionAndAuthResolver } from './core/resolvers/permissionAndAuthResolver';
import { permissionResolver } from './core/resolvers/permissionResolver';
import { AppLayoutComponent } from './shared/components/app-layout/app-layout.component';
import { CallbackComponent } from './shared/components/callback-component';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';
import { AdminLayoutComponent } from './shared/components/admin-layout/admin-layout.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { AuthResolver } from './core/resolvers/authResolver';

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    resolve: { data: permissionResolver },
    children: [
      {
        path: '',
        redirectTo: 'news',
        pathMatch: 'full'
      },
      {
        path: 'news',
        loadChildren: () => import('./features/public/news/news.module').then(m => m.NewsModule)
      },
      {
        path: 'members',
        loadChildren: () => import('./features/public/members/members.module').then(m => m.MembersModule)
      },
      {
        path: 'interclub',
        loadChildren: () => import('./features/public/interclub/interclub.module').then(m => m.InterClubModule)
      },
      {
        path: 'tournaments',
        loadChildren: () => import('./features/public/tournaments/tournaments.module').then(m => m.TournamentsModule)
      },
      {
        path: 'rankings',
        loadChildren: () => import('./features/public/rankings/rankings.module').then(m => m.RankingsModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./features/authenticated/profile/profile.module').then(m => m.ProfileModule),
        resolve: { data: AuthResolver }
      },
      {
        path: 'profile/settings',
        loadChildren: () => import('./features/authenticated/settings/settings.module').then(m => m.SettingsModule),
        resolve: { data: AuthResolver }
      },
      {
        path: 'authenticated',
        loadChildren: () => import('./features/authenticated/authenticated.module').then(m => m.AuthenticatedModule)
      },
    ]
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    loadChildren: () => import('./features/admin/admin.module').then(m => m.AdminModule),
    data: {
      permission: { name: 'module.view.administration', category: 'None', type: 'View' }
    },
    resolve: { data: permissionAndAuthResolver }
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'callback', component: CallbackComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: "enabledNonBlocking",
    paramsInheritanceStrategy: 'always'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
