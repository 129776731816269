import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';

export interface ErrorPageModel {
  message: string;
  extra: string[];
  status: string;
}

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss'
})
export class ErrorPageComponent implements OnInit {
  @Input() model: ErrorPageModel | undefined;

  constructor(private location: Location) {

  }
  ngOnInit(): void {
    console.log('model', this.model)
  }

  back() {
    this.location.back();
  }
}
