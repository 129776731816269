import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { catchError, Observable, of, throwError } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private readonly msg: MessageService) { }

  private handleError(error: HttpErrorResponse): Observable<any> {
    let errorMsg = '';

    if (error.error instanceof ErrorEvent) {
      this.msg.add({
        severity: 'error',
        summary: `HTTP Error - ${error.status}`,
        detail: error && error.message,
        life: 5000
      });
      errorMsg = `Error: ${error.error.message}`;
    } else {
      //handle your auth error or rethrow
      if (error.status === 401 || error.status === 403) {
        //navigate /delete cookies or whatever
        console.log('sending unauthorized from Error Interceptor')
        this.router.navigateByUrl(`/unauthorized`);
        // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
        return of(error.message); // or EMPTY may be appropriate here
      }
      else if (error.status === 404) { /* User doesn't wanna see 404 error */ }
      else {
        this.msg.add({
          severity: 'error',
          summary: `HTTP Error - ${error.status}`,
          detail: error && error.message,
          life: 5000
        });
        errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        console.log(error)
      }
    }

    return throwError(() => error);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators
    return next.handle(req).pipe(catchError(x => this.handleError(x))); //here use an arrow function, otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70
  }
}
