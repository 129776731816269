import { HttpClient } from "@angular/common/http";
import { MissingTranslationHandler, TranslateParser, MissingTranslationHandlerParams } from "@ngx-translate/core";
import { Translation } from "./models/translation.model";

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
    constructor(private http: HttpClient, private parser: TranslateParser) {
  
    }
  
    handle(params: MissingTranslationHandlerParams): string {
      this.http.get<Translation>(`globalization/${params.translateService.currentLang}/${params.key}`).subscribe(_ => {});
  
      if (params.interpolateParams) {
        return this.parser.interpolate(params.key + `{{${params.interpolateParams}}}`, params.interpolateParams) as string;
      } else {
        return params.key;
      }
    }
  }