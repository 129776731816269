export const environment = {
  production: true,
  environment: 'test',
  appVersion: require('../../package.json').version,
  consentDomain: 'snookervlaanderen-test.resonate.be',
  settings: {
    documentBase: 'https://snookervlaanderen-api-test.resonate.be',
    serviceBase: 'https://snookervlaanderen-api-test.resonate.be/api/',
    graphQLBase: 'https://snookervlaanderen-api-test.resonate.be/graphql/'
  },
  auth0: {
    domain: 'snookervlaanderen-test-resonate.eu.auth0.com',
    audience: 'https://snookervlaanderen-api-test.resonate.be',
    clientId: 'jJVCdPQXcUhj3URO8OL5V5SdRtUf7Xi7',
    redirectUri: 'https://snookervlaanderen-test.resonate.be/callback',
  }
};
