<div class="sidebar" [ngClass]="{'collapsed': sideBarState}">
  <div class="brand p-2">
    <a routerLink="/admin">
      <img class="logo-shadow" src="assets/images/logosite.png" height="100" title="..." />
    </a>
  </div>
  <hr class="sidebar-divider my-0" />
  <div class="sidebar-section">
    <ul class="navbar-nav">
      <li class="nav-item" [action]="{name:'module.view.administration',  type: 'View', category: 'None'}">
        <a class="nav-link" routerLink="/admin" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
          <fa-icon class="nav-icon" [icon]="faHouse"></fa-icon>
          <span>{{ 'module.administration.title' | translate }}</span>
        </a>
      </li>
    </ul>
    <hr class="sidebar-divider" />
  </div>
  <div class="sidebar-section">
    <div class="sidebar-heading">
      {{ 'admin.section.title.content-management' | translate }}
    </div>
    <ul class="navbar-nav">
      <li class="nav-item" [action]="{name:'module.view.administration.content',  type: 'View', category: 'None'}">
        <a class="nav-link" [routerLink]="['content']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
          <fa-icon class="nav-icon" [icon]="faNewspaper"></fa-icon>
          <span>{{ 'module.content.title' | translate }}</span>
        </a>
      </li>
      <li class="nav-item" [action]="{name:'module.view.administration.tags',  type: 'View', category: 'None'}">
        <a class="nav-link" [routerLink]="['content', 'tags']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
          <fa-icon class="nav-icon" [icon]="faTag"></fa-icon>
          <span>{{ 'module.tags.title' | translate }}</span>
        </a>
      </li>
      <li class="nav-item" [action]="{name:'module.view.administration.attachments',  type: 'View', category: 'None'}">
        <a class="nav-link" [routerLink]="['attachments']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
          <fa-icon class="nav-icon" [icon]="faPaperclip"></fa-icon>
          <span>{{ 'module.attachments.title' | translate }}</span>
        </a>
      </li>
    </ul>
    <hr class="sidebar-divider" />
  </div>
  <div class="sidebar-section">
    <div class="sidebar-heading">
      {{ 'admin.section.title.interclub-management' | translate }}
    </div>
    <ul class="navbar-nav">
      <li class="nav-item"
        [action]="{name:'module.view.administration.interclub.seasons',  type: 'View', category: 'None'}">
        <a class="nav-link" [routerLink]="['interclub', 'seasons']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
          <fa-icon class="nav-icon" [icon]="faLeaf"></fa-icon>
          <span>{{ 'module.interclub.seasons.title' | translate }}</span>
        </a>
      </li>
      <li class="nav-item"
        [action]="{name:'module.view.administration.interclub.divisions', type: 'View', category: 'None'}">
        <a class="nav-link" [routerLink]="['interclub', 'divisions']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
          <fa-icon class="nav-icon" [icon]="faChess"></fa-icon>
          <span>{{ 'module.interclub.divisions.title' | translate }}</span>
        </a>
      </li>
      <li class="nav-item"
        [action]="{name:'module.view.administration.interclub.teams',  type: 'View', category: 'None'}">
        <a class="nav-link" [routerLink]="['interclub', 'teams']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
          <fa-icon class="nav-icon" [icon]="faPeopleGroup"></fa-icon>
          <span>{{ 'module.interclub.teams.title' | translate }}</span>
        </a>
      </li>
      <li class="nav-item"
        [action]="{name:'module.view.administration.interclub.calendars.types',  type: 'View', category: 'None'}">
        <a class="nav-link" [routerLink]="['interclub', 'calendars', 'types']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
          <fa-icon class="nav-icon" [icon]="faCalendar"></fa-icon>
          <span>{{ 'module.interclub.calendars.types.title' | translate }}</span>
        </a>
      </li>
      <li class="nav-item"
        [action]="{name:'module.view.administration.interclub.calendars',  type: 'View', category: 'None'}">
        <a class="nav-link" [routerLink]="['interclub', 'calendars']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
          <fa-icon class="nav-icon" [icon]="faCalendarAlt"></fa-icon>
          <span>{{ 'module.interclub.calendars.title' | translate }}</span>
        </a>
      </li>
      <li class="nav-item"
        [action]="{name:'module.view.administration.interclub.games',  type: 'View', category: 'None'}">
        <a class="nav-link" [routerLink]="['interclub', 'games']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
          <fa-icon class="nav-icon" [icon]="faHandshake "></fa-icon>
          <span>{{ 'module.interclub.games.title' | translate }}</span>
        </a>
      </li>
    </ul>
    <hr class="sidebar-divider" />
  </div>
  <div class="sidebar-section">
    <div class="sidebar-heading">
      {{ 'admin.section.title.authorization' | translate }}
    </div>
    <ul class="navbar-nav">
      <li class="nav-item" [action]="{name:'module.view.administration.roles',  type: 'View', category: 'None'}">
        <a class="nav-link" [routerLink]="['roles']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <fa-icon class="nav-icon" [icon]="faBuildingShield"></fa-icon>
          <span>{{ 'module.admin.roles.title' | translate }}</span>
        </a>
      </li>
      <li class="nav-item" [action]="{name:'module.view.administration.permissions',  type: 'View', category: 'None'}">
        <a class="nav-link" [routerLink]="['permissions']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <fa-icon class="nav-icon" [icon]="faUserLock"></fa-icon>
          <span>{{ 'module.admin.permissions.title' | translate }}</span>
        </a>
      </li>
      <li class="nav-item"
        [action]="{name:'module.view.administration.applicationactions',  type: 'View', category: 'None'}">
        <a class="nav-link" [routerLink]="['applicationactions']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <fa-icon class="nav-icon" [icon]="faAtom"></fa-icon>
          <span>{{ 'module.admin.applicationactions.title' | translate }}</span>
        </a>
      </li>
    </ul>
    <hr class="sidebar-divider" />
  </div>
  <div class="sidebar-section">
    <div class="sidebar-heading">
      {{ 'admin.section.title.clubs-and-members' | translate }}
    </div>
    <ul class="navbar-nav">
      <li class="nav-item" [action]="{name:'module.view.administration.users',  type: 'View', category: 'None'}">
        <a class="nav-link" [routerLink]="['users']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <fa-icon class="nav-icon" [icon]="faUsers"></fa-icon>
          <span>{{ 'module.users.title' | translate }}</span>
        </a>
      </li>
      <li class="nav-item" [action]="{name:'module.view.administration.clubs',  type: 'View', category: 'None'}">
        <a class="nav-link" [routerLink]="['clubs']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <fa-icon class="nav-icon" [icon]="faHouse"></fa-icon>
          <span>{{ 'module.admin.clubs.title' | translate }}</span>
        </a>
      </li>
    </ul>
    <hr class="sidebar-divider" />
  </div>
  <div class="sidebar-section">
    <div class="sidebar-heading">
      {{ 'admin.section.title.i18n' | translate }}
    </div>
    <ul class="navbar-nav">
      <li class="nav-item" [action]="{name:'module.view.administration.translations',  type: 'View', category: 'None'}">
        <a class="nav-link" [routerLink]="['translations']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <fa-icon class="nav-icon" [icon]="faGlobe"></fa-icon>
          <span>{{ 'module.translations.title' | translate }}</span>
        </a>
      </li>
      <li class="nav-item" [action]="{name:'module.view.administration.languages',  type: 'View', category: 'None'}">
        <a class="nav-link" [routerLink]="['languages']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <fa-icon class="nav-icon" [icon]="faLanguage"></fa-icon>
          <span>{{ 'module.languages.title' | translate }}</span>
        </a>
      </li>
    </ul>
    <hr class="sidebar-divider" />
  </div>
  <div class="sidebar-section">
    <div class="sidebar-heading">
      {{ 'admin.section.title.communication' | translate }}
    </div>
    <ul class="navbar-nav">
      <li class="nav-item" [action]="{name:'module.view.administration.email',  type: 'View', category: 'None'}">
        <a class="nav-link" [routerLink]="['email', 'template']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <fa-icon class="nav-icon" [icon]="faEnvelope"></fa-icon>
          <span>{{ 'module.email.title' | translate }}</span>
        </a>
      </li>

      <li class="nav-item"
        [action]="{name:'module.view.administration.email-campaign',  type: 'View', category: 'None'}">
        <a class="nav-link" [routerLink]="['email', 'campaign']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <fa-icon class="nav-icon" [icon]="faMailBulk"></fa-icon>
          <span>{{ 'module.email-campaign.title' | translate }}</span>
        </a>
      </li>
    </ul>
    <hr class="sidebar-divider" />
  </div>
</div>
