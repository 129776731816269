import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { ApplicationAction } from "../models/applicationaction.model";
import { AuthRepository } from "@app/store/auth/auth.repository";

@Injectable({
  providedIn: 'root'
})
export class ApplicationActionsService {
  constructor(private http: HttpClient,
    private authRepository: AuthRepository) {

  }

  public getApplicationActions(): Observable<ApplicationAction[]> {
    return this.http.get<ApplicationAction[]>('policyserver/applicationactions');
  }

  public getApplicationActionsForUser() {
    return this.http.get<ApplicationAction[]>('policyserver/applicationactions/user')
      .pipe(tap((actions) => this.authRepository.updateApplicationActions(actions)));
  }
}
