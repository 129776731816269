import { CommonModule, JsonPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { AppLayoutComponent } from './components/app-layout/app-layout.component';
import { AppNavComponent } from './components/app-nav/app-nav.component';
import { DebugViewerComponent } from './components/debug-viewer/debug-viewer.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AdminLayoutComponent } from './components/admin-layout/admin-layout.component';
import { AdminNavComponent } from './components/admin-nav/admin-nav.component';
import { CoreModule } from '@app/core/core.module';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AdminTopNavComponent } from './components/admin-top-nav/admin-top-nav.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { FileUploadModule } from 'primeng/fileupload';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { DialogModule } from 'primeng/dialog';
import { SliderModule } from 'primeng/slider';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoaderComponent } from './components/loader/loader.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { AvatarPhotoComponent } from './components/avatar-photo/avatar-photo.component';
import { SkeletonModule } from 'primeng/skeleton';

@NgModule({
  declarations: [
    AppFooterComponent,
    AppLayoutComponent,
    AppNavComponent,
    LanguageSelectorComponent,
    UnauthorizedComponent,
    AdminLayoutComponent,
    AdminNavComponent,
    AdminTopNavComponent,
    PageNotFoundComponent,
    FileUploadComponent,
    DebugViewerComponent,
    ErrorPageComponent,
    LoaderComponent,
    PageTitleComponent,
    AvatarPhotoComponent
  ],
  imports: [
    CommonModule,
    DropdownModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    ToastModule,
    BreadcrumbModule,
    DialogModule,
    FileUploadModule,
    SliderModule,
    CoreModule,
    AngularCropperjsModule,
    ProgressSpinnerModule,
    SkeletonModule
  ],
  exports: [
    AppFooterComponent,
    AppNavComponent,
    AppLayoutComponent,
    FontAwesomeModule,
    LanguageSelectorComponent,
    FileUploadComponent,
    DebugViewerComponent,
    LoaderComponent,
    PageTitleComponent,
    AvatarPhotoComponent,
    SkeletonModule
  ]
})
export class SharedModule { }
