<div class="min-vh-100 d-flex flex-column">
  <app-nav></app-nav>

  <main role="main" class="container flex-grow-1">
    <router-outlet></router-outlet>
  </main>

  <app-footer></app-footer>

  <p-toast></p-toast>

  <button class="scroll-top p-2" *ngIf="showToTop" (click)="gotoTop()" title="top">
    <fa-icon [icon]="faArrowUp"></fa-icon>
  </button>
</div>
