import { inject } from "@angular/core";
import { ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { ApplicationActionsService } from "@app/features/admin/applicationactions/services/applicationactions.service";
import { AuthService } from "@auth0/auth0-angular";
import { firstValueFrom, lastValueFrom, of } from "rxjs";

export const permissionResolver: ResolveFn<any> = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const applicationActionService = inject(ApplicationActionsService);
    const authService = inject(AuthService);

    const isAuthenticated = await firstValueFrom(authService.isAuthenticated$);

    if (isAuthenticated) {
        await lastValueFrom(applicationActionService.getApplicationActionsForUser());
    }
    return of(true);
};
