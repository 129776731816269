import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AuthDirective } from './directives/auth.directive';
import { AsPipe } from './pipes/aspipe.pipe';
import { WeekdayPipe } from './pipes/weekday.pipe';
import { FeatureFlagDirective } from './directives/feature-flag.directive';

@NgModule({
  declarations: [
    AuthDirective,
    AsPipe,
    WeekdayPipe
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule
  ],
  exports: [
    TranslateModule,
    ReactiveFormsModule,
    AuthDirective,
    WeekdayPipe
  ],
})
export class CoreModule { }
