import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { City } from "../models/city.model";
import { District } from "../models/district.model";

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  constructor(private http: HttpClient) { }

  getAllCitiesByCountryCode(isoCode: string): Observable<City[]> {
    return this.http.get<City[]>(`masterdata/${isoCode}/cities`);
  }

  getAllDistrictsByCountryCode(isoCode: string): Observable<District[]> {
    return this.http.get<District[]>(`districts/iso/${isoCode}`);
  }

  getDistrictById(id: string): Observable<District> {
    return this.http.get<District>(`districts/${id}`);
  }
}
