import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {
  environment = environment.environment;
  appVersion = environment.appVersion;
  form!: FormGroup;
  currentYear!: number;

  constructor(
    fb: FormBuilder
  ) {
    this.form = fb.group({
      email: fb.control('', Validators.email)
    });
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }

  onSubscribe(): void {
  }

}
