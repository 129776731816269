import { HttpClient } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { Observable, forkJoin, map, of } from "rxjs";
import { Translation } from "./models/translation.model";

export class TranslationLoader implements TranslateLoader {
  constructor(private http: HttpClient) { }

  getTranslation(lang: string): Observable<any> {
    return this.http.get<Translation[]>(`globalization/${lang}`).pipe(map(response => {
      const obj = response.reduce((acc, { translationKey, localizedValue }) => ({ ...acc, [translationKey]: localizedValue }), {});
      return obj;
    }));
  }
}
